<template>
  <v-container class="cont">
    <div class="heroimg">

      <img
                contain
                transition="slide-x-transition"
                class="juh "
                height="945"
                 style="position: absolute;
  
    right: -300px;
    top: -300px;"
                src="../assets/Poly.png"
              />
      <v-card tile flat class="grdd mx-auto transparent pa-5" width="1300">
        <v-row>
          <v-col cols="12" md="6">
            <v-card class="pa-2 transparent" flat tile>
              <h1 class="hdr">Tamba exists to help you grow your money</h1>
              <p class="hdrr">
                Take control with the best designed investment, saving,
                budgeting and personal financial management platform.
              </p>

              <div>
                <v-btn
                  dark
                  rounded
                  depressed
                  x-large
                  class="btn reds mt-6"
                  color="#64B4CD"
                  style="padding: 10px 40px !important;"
                >
                  Join us
                </v-btn>
              </div>
            </v-card>
          </v-col>

          <v-col cols="12" md="6" class="hero">
            <v-card class="pa-2 transparent" flat tile>
              <img
                contain transition="slide-x-transition"
                class="moock fltr " style="    height: 910px;
    margin-top: -190px;" 
                src="../assets/h1.png"
              />
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </div>

    <v-card tile flat class="grd mx-auto transparent pa-5" width="1300">
      <v-row >
        <v-col cols="12" md="6" class="hero">
          <v-card class="pa-2 transparent" flat tile>
            <img
              contain transition="slide-x-transition"
              class="moock fltr hodd" style="    height: 1335px;
    margin-top: -350px; margin-left: -700px;"
              src="../assets/t22.png" 
            />
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <v-card class="pa-2 transparent" flat tile>
            <div class="sep">
              <v-btn fab depressed color="#FFC431" class="float-left mr-4">
                <v-icon medium>mdi-bank</v-icon></v-btn
              >
              <h4>Reach Your Dream!</h4>
              <p class="subtll ma-0">
                In under 5 minutes, get investment accounts for you and your
                family, plus saving, personal finance management, ways to earn
                more money, and grow your knowledge. With just KES 100, KES 300,
                or KES 500 a month your dream can become a reality.
              </p>
            </div>
            <div class="sep">
              <v-btn fab depressed color="#FFC431" class="float-left mr-4">
                <v-icon medium>mdi-cellphone</v-icon></v-btn
              >

<h4>As-You-Go</h4>

              <p class="subtll ma-0">
                Invest as you go using TambaTax TM . Set aside money from each transaction on mobile money,
bank transfer, credit cards, debit cards and donations.

              </p>
            </div>
            <div class="sep">
              <v-btn fab depressed color="#FFC431" class="float-left mr-4">
                <v-icon medium>mdi-chart-box</v-icon></v-btn
              >

<h4>Prudent
</h4>

              <p class="subtll ma-0">
                Access prudent portfolios designed by experts, that adjust automatically as you and your money
grow.

              </p>
            </div>
            <div class="sep">
              <v-btn fab depressed color="#FFC431" class="float-left mr-4">
                <v-icon medium>mdi-finance</v-icon></v-btn
              >

<h4>Regulated
</h4>

              <p class="subtll ma-0">
                Regulated by Central Bank of Kenya, Capital Markets Authority and backed by leading investors
and innovators in Africa’s leading fintech ecosystem.

              </p>
            </div>
            <div class="sep">
              <v-btn fab depressed color="#FFC431" class="float-left mr-4">
                <v-icon medium>mdi-security</v-icon></v-btn
              >

<h4>Security
</h4>

              <p class="subtll ma-0">
                We use bank-level security, 256-bit encryption, and allow two-factor authentication for added
security.
              </p>
            </div>
             
          </v-card>
        </v-col>
      </v-row>






 <v-row id="inv">
        <v-col cols="12" md="5" class="hero">
          <v-card class="pa-2 transparent" flat tile>

<h1 class="hdrx">Tamba Invest


</h1>
<p class="subtll ma-0">
                The Africa focused investment platform that allows you to invest as-you-go. Set up in under 5
minutes and automatically add money to your diversified portfolio with real time recording,
reporting and access

              </p>


            
          </v-card>
        </v-col>

        <v-col cols="12" md="7">
          <v-card dark color="#000" style="border-radius:10px;" class="pa-6 " flat >

            <h3 class="mb-4">Recurrent Investing
</h3>
             <p style="font-weight:300;font-size:14px;line-height: 23px" class="subtllx ma-0">
                
An easy, automated way to save and invest with daily, weekly, monthly and annual reminders.
We provide a platform that offers you access to the assets that help you reach your dreams as
you invest, save, and spend prudently. Get started in under a minute.
              </p>
<v-btn
                  dark
                  rounded
                  depressed
                  large
                  class="btn reds mt-6"
                  color="#21BA9B"
                >
                  Join us
                </v-btn>



          </v-card>
        </v-col>
      </v-row>








<v-row class=" tyu" id="edu" style="    margin-top: 250px;">
          <v-col cols="12" md="6">
            <v-card class="pa-2 transparent" flat tile>
              <h1 class="hdrx mb-5" style="width: 395px;">What Is a Portfolio Investment?
</h1>
              <p class="hdrr mb-6" style="font-size: 16px; line-height:25px;">
                A portfolio investment is ownership of a stock, bond, or other financial asset with the expectation that it will earn a return or grow in value over time, or both. It entails passive or hands-off
ownership of assets as opposed to direct investment, which would involve an active management role. – Investopedia

              </p>
 

 <p style="font-size: 16px;"><b>Characteristics of a Portfolio Investment</b>
<ul>
  <li> portfolio investment is an asset that is purchased in the expectation that it will earn a
return or grow in value, or both.</li>
<li>A portfolio investment is passive, unlike a direct investment, which implies hands-on
management.</li>
<li>Risk tolerance and time horizon are key factors in selecting any portfolio investment.</li>
</ul>
<br>
<b>Stocks and Bonds</b><br>
Stocks are shares of ownership in a business.
<br><br>
Bonds are a debt investment where an investor loans money to a business or government for a
defined period of time and interest rate.</p>
            </v-card>
          </v-col>

          <v-col cols="12" md="6" class="hero">
            <v-card class="pa-2 transparent" flat tile>
              <img
                contain
                class="moock fltr"
                
                style="    height: 1300px;
    margin-top: -350px;"
                src="../assets/t3.png"
              />
            </v-card>
          </v-col>
        </v-row>











<v-row class=" tyu" >


<v-col cols="12" md="6" class="hero">
            <v-card class="pa-2 transparent" flat tile>
              <img
                contain
                class="moock fltr hodd"
                
                style="    height: 550px;float:right;margin-right: 20%;"
                src="../assets/t222.png"
              />
            </v-card>
          </v-col>


          <v-col cols="12" md="6">
            <v-card class="pa-2 transparent" flat tile>
              
<h1 class="hdrx" style="width:315px">How to Create
a Portfolio</h1>

             <p class="subtll ma-0">
             To create a portfolio, tell us your financial situation and goals. With those answers, Tamba will
recommend you a mix of investment assets that will become your portfolio.

              </p>
 


            <h3 class="my-3 mt-6">Recurrent Investing
</h3>
             <p class="subtll ma-0">
                
An asset class is a group of financial instruments which have similar financial characteristics
and behave similarly in the marketplace.
<ul>
   <li>Fixed income (bonds)</li>
<li>Equities (stocks)</li>
<li>Cash and cash equivalents</li>
<li>Commodities</li>
<li> Real estate</li>
<li>Infrastructure</li>
<li>Private equity</li>
<li>Hedge funds</li>
<li>Art and collectibles</li>
<li>Insurance</li></ul>
              </p>
            </v-card>
          </v-col>

          
        </v-row>


























<!-- 




<h1 class="hdrx" style="width:315px">How to Create
a Portfolio</h1>


 <v-row class="seg mt-0" id="edu" >


   
        <v-col cols="12" md="6" class="hero">
          
          <v-card class="pa-2 transparent" flat tile>


<p class="subtll ma-0">
             To create a portfolio, tell us your financial situation and goals. With those answers, Tamba will
recommend you a mix of investment assets that will become your portfolio.

              </p>

<v-img 
          color="#999"
          height="32"
          width="32"
          contain
          class="my-6"
        ></v-img>

            
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <v-card color="#444" class="pa-2 transparent" flat tile>

            <h3 class="mb-3">Recurrent Investing
</h3>
             <p class="subtll ma-0">
                
An asset class is a group of financial instruments which have similar financial characteristics
and behave similarly in the marketplace.
<ul>
   <li>Fixed income (bonds)</li>
<li>Equities (stocks)</li>
<li>Cash and cash equivalents</li>
<li>Commodities</li>
<li> Real estate</li>
<li>Infrastructure</li>
<li>Private equity</li>
<li>Hedge funds</li>
<li>Art and collectibles</li>
<li>Insurance</li></ul>
              </p>



          </v-card>
        </v-col>
      </v-row> -->


<h3 class="my-6 text-center" style="margin-top: 100px !important;">Asset Sub-Classes</h3>

<v-row>
  
      <v-col >
        <v-card
          class="sect"
        flat
        >
         <h4 class="mb-6" >Large-cap stocks</h4> 
         <p>Shares issued by companies with a market capitalization above $10
billion.</p>
        </v-card>
      </v-col>

            <v-col >
        <v-card
          class="sect"
         flat
        >
          <h4 class="mb-6">Mid-cap stocks</h4> 
          <p>Shares issued by companies with a market capitalization between $2
billion and $10 billion.</p>
        </v-card>
      </v-col>

            <v-col >
        <v-card
          class="sect"
         flat
        >
          <h4 class="mb-6">Real estate investment trusts (REITs)</h4> 
          <p>Shares in an investor pool of mortgages or
properties.</p>
        </v-card>
      </v-col>

            <v-col >
        <v-card
          class="sect"
          flat
        >
          <h4 class="mb-6">International securities</h4> 
          <p>Any security issued by a foreign company and listed on a
foreign exchange.</p>
        </v-card>
      </v-col>
    </v-row>


<v-row>
      <v-col >
        <v-card
          class="sect"
        flat
        >
         <h4 class="mb-6" >Fixed-income securities</h4> 
         <p>Highly rated corporate or government bonds that pay the
holder a set amount of interest, periodically or at maturity, and return the principal at the
end of the period, these securities are less volatile and less risky than stocks.</p>
        </v-card>
      </v-col>

            <v-col >
        <v-card
          class="sect"
         flat
        >
          <h4 class="mb-6">Money Market</h4> 
          <p>Investments in short-term debt, typically a year or less, Treasury bills
(T-bills) are the most common money market investment.</p>
        </v-card>
      </v-col>

            <v-col >
        <v-card
          class="sect"
         flat
        >
          <h4 class="mb-6">Small-cap stocks</h4> 
          <p>Companies with a market capitalization of less than $2 billion. These
equities tend to have a higher risk due to their lower liquidity.</p>
        </v-card>
      </v-col>

            <v-col >
        <v-card
          class="sect"
          flat
        >
          <h4 class="mb-6">Emerging markets</h4> 
          <p>Securities issued by companies in developing nations. These
investments offer a high potential return and a high risk, due to their potential for country
risk and their lower liquidity.</p>
        </v-card>
      </v-col>
    </v-row>


 


    </v-card>




  <div class="heroimgx seg">
<v-card tile flat class="grd mx-auto transparent" style=" padding: 80px 30px;" width="1300">
<v-row>
          <v-col cols="12">
            <v-card class="pa-2 transparent" flat tile>
              <h1 class="hdrx" style="color:#fff;">Diversification is Key</h1>
              <p class="mt-3" style="font-weight: 300; line-height: 30px; color: #fff;">
Diversification is a risk management strategy that mixes a wide variety of investments within a
portfolio. A diversified portfolio contains a mix of distinct asset types and investment vehicles in
an attempt at limiting exposure to any single asset or risk. The rationale behind this technique is
that a portfolio constructed of different kinds of assets will, on average, yield higher long-term
returns and lower the risk of any individual holding or security.
              </p>

 
            </v-card>
          </v-col>

</v-row>
</v-card>
    </div>


<v-card tile flat class="grd mx-auto transparent pa-5" width="1300">


  <v-row   id="por">
        <v-col cols="12" md="2" class="hero " align="center">
          <v-card class="pa-2 transparent" flat tile>
             <h1 class="hdrx  bgh d-flex justify-center"  style="    min-height: 450px;
    align-items: center;">Portfolio Profile</h1>
          </v-card>
        </v-col>

        <v-col cols="12" md="10">
          <v-card class="pa-2 transparent" flat tile>
            <div class="sep sepx">
              <v-btn fab depressed color="#FFC431" class="float-left mr-4">
        </v-btn
              >
               
              <p class="subtll ma-0"><pol>Conservative:</pol> Low-risk portfolio mix designed to match or slightly outpace the average rate of inflation. Ideal for investors who have low tolerance for risk or are either near
retirement or currently retired



              </p>
            </div>
            <div class="sep sepx"  style="margin-left: 25px">
              <v-btn fab depressed color="#FFC431" class="float-left mr-4">
        </v-btn
              >

 

              <p class="subtll ma-0">
                <pol>Moderate Aggressive:</pol> Mainly consist of equities, so their value can fluctuate widely
from day-to-day. If you have an aggressive portfolio, your main goal is to achieve long-term growth of capital. The strategy of an aggressive portfolio is often called a capital
growth & strategy. To provide diversification, investors with aggressive portfolios usually
add some fixed-income securities.


              </p>
            </div>
            <div class="sep sepx"  style="margin-left: 50px">
              <v-btn fab depressed color="#FFC431" class="float-left mr-4">
        </v-btn
              >

 

              <p class="subtll ma-0">
               <pol>Moderate:</pol> Balanced portfolios with asset composition divided almost equally between
fixed-income securities and equities. The balance is between growth and income. Since
moderately aggressive portfolios have a higher level of risk than conservative portfolios,
this strategy is best for investors with a longer time horizon (generally more than five
years) and a medium level of risk tolerance.

              </p>
            </div>
            <div class="sep sepx"  style="margin-left: 25px">
              <v-btn fab depressed color="#FFC431" class="float-left mr-4">
        </v-btn
              >

 

              <p class="subtll ma-0">
               <pol>Moderate Conservative:</pol> Ideal for the investor who wishes to preserve most of the
portfolio’s total value but is willing to take on some risk for inflation protection. A common
strategy within this risk level is called &quot;current income.&quot; With this strategy, you choose securities that pay a high level of dividends or coupon payments.

              </p>
            </div>
            <div class="sep sepx">
              <v-btn fab depressed color="#FFC431" class="float-left mr-4">
                </v-btn
              >
 

              <p class="subtll ma-0">
                <pol>Aggressive:</pol> High-risk portfolio mix designed to maximize returns. Strategies for
achieving higher than average returns typically emphasize capital appreciation as a primary investment objective, rather than income or safety of principal. Such a strategy
would therefore have an asset allocation with a substantial weighting in stocks and
possibly little or no allocation to bonds or cash. Ideal for investors who have high
tolerance for risk.

              </p>
            </div>
             
          </v-card>
        </v-col>
      </v-row>





 <v-row class="seg" >


   
        <v-col cols="12" sm="6">
          
          <v-card class=" pt-6 transparent" flat tile>

<h1 class="hdrx" style=" 
    align-items: center;width:200px">Portfolio Mix</h1>
 
          </v-card>
        </v-col>


   
        <v-col cols="12" sm="6">
          
          <v-card class="sectx" flat tile>

<h3 class="mb-4">Moderate Portfolio Mix:
</h3>

<p class="subtll ma-0">
    <ul>
      <li>Large Company Stocks 35%</li>
<li>Medium Company Stocks 5%</li>
<li>Small Company Stocks 2%</li>
<li>International Company Stocks 18%</li>
<li>Government &amp; Corporate Bonds 40%</li>
      </ul>

              </p>
 
          </v-card>
        </v-col>


   
        <v-col cols="12" sm="6">
          
          <v-card class="sectx" flat tile>

<h3 class="mb-4">Moderate Portfolio Mix:
</h3>

<p class="subtll ma-0">
   <ul>
     <li>Short Term Government Bonds 40%</li>
<li>Short Term Corporate Bonds 40%</li>
<li>Ultra Short Term Government Bond (30 Day TBills) 20%
</li>
     </ul>

              </p>
 
          </v-card>
        </v-col>


   
        <v-col cols="12" sm="6">
          
          <v-card class="sectx" flat tile>

<h3 class="mb-4">Moderate Portfolio Mix:
</h3>

<p class="subtll ma-0">
    <ul>
      <li>Large Company Stocks 47%</li>
<li>Medium Company Stocks 6%</li>
<li>Small Company Stocks 3%</li>
<li>International Company Stocks 24%</li>
<li>Government &amp; Corporate Bonds 20%
</li>
      </ul>

              </p>
 
          </v-card>
        </v-col>



   
        <v-col cols="12" sm="6">
          
          <v-card class="sectx" flat tile>

<h3 class="mb-4">Moderate Portfolio Mix:
</h3>

<p class="subtll ma-0">
    <ul>
      <li>Large Company Stocks 24%</li>
<li>Medium Company Stocks 4%</li>
<li>International Company Stocks 12%</li>
<li>Government &amp; Corporate Bonds 60%</li>
      </ul>

              </p>
 
          </v-card>
        </v-col>


        
        <v-col cols="12" sm="6">
          
          <v-card class="sectx" flat tile>

<h3 class="mb-4">Moderate Portfolio Mix:
</h3>

<p class="subtll ma-0">
    <ul>
      <li>Large Company Stocks 55%</li>
 <li>Medium Company Stocks 10%</li>
 <li>Small Company Stocks 5%</li>
 <li>International Company Stocks 30%
</li>
      </ul>

              </p>
 
          </v-card>
        </v-col>



 </v-row>




 <v-row class="seg" id="pri" >


         <v-col cols="12" md="6">
          
          <v-card  flat tile>


<h1 class="hdrx" >Pricing</h1>
<p class="subtll">All charges tied to fund management fee of 1%</p>
    </v-card>
 </v-col>
          
        

 </v-row>



   </v-card>







<div class="footr pt-6">

<v-card tile flat class="grd mx-auto transparent pa-5" width="1300">


<v-row >


         <v-col cols="12" md="4">
          
          <v-card dark class="transparent" flat tile>
   <img
            class="mb-3"
            height="90" 
            src="../assets/logobw.png"
            style="filter: grayscale;"
          />

<br>

<!-- <p>Tamba exists to help you grow your money</p> -->


 <v-spacer></v-spacer>
<p>Copyright Tamba - All rights reserved.</p>
 </v-card>
 </v-col>



         <v-col cols="12" md="4">
          
          <v-card dark class="transparent" flat tile>
 <div class="links">
            
            <a href="/#inv" class="mx-5">Investing</a>
            <a href="/#edu" class="mx-5">Educate</a>
            <a href="/#por" class="mx-5">Portolio</a>
            <a href="/#pri" class="mx-5">Pricing</a> 
            <a href="/privacy-policy" class="mx-5">Privacy Policy</a> 
            <a href="/terms-of-service" class="mx-5">Terms & Conditions</a> 
          </div>
 </v-card>
 </v-col>



         <v-col cols="12" md="4">
          
          <v-card dark class="transparent" flat tile>
<!-- <h2>Download App</h2> -->
<div style="width:100%">
   <img
            class="mb-3"
            height="35" 
            src="../assets/gl.png"
          />
</div>
<div style="width:100%">
  <img
            class="mb-3"
            height="35" 
            src="../assets/ap.png"
          />
</div>
<br>
 
 </v-card>
 </v-col>



</v-row>


        
          
         








</v-card>
  </div>






  </v-container>
</template>

<script>
export default {
  data: () => ({}),
};
</script>

<style scoped>
body {
  font-family: poppins !important;
}

.cont {
  max-width: 100% !important;
  padding: 0 !important;
}

.btn {
  text-transform: capitalize !important;
  /* font-size: 16px !important; */
  letter-spacing: 0 !important;
  padding: 10px 20px !important;
  /* background: #e5e5e5; */
}

/* .heroimg {
  position: absolute;
  width: 100%; 
  height: 100vh;
  left: 0px;
  top: 0px;
  background: linear-gradient(
    130.71deg,
    #a0ffec 24.71%,
    rgba(249, 225, 67, 0.5) 53.78%,
    #f9f9f9 80.1%
  );
} */

.heroimgx {
  width: 100%;
  background: linear-gradient(279.91deg, #ffc431 -6.3%, #64b4cd 98.35%);
}

pol {
  font-size: 18px;
  font-weight: 900;
}

.hdr {
  font-weight: bolder;
  font-size: 41px;
  padding-top: 70px;
  margin-bottom: 20px;
  line-height: 61px;
  text-shadow: 0px 12.8392px 14.8145px rgba(0, 0, 0, 0.1);
}
.hdrx {
  font-size: 36px;
  line-height: 59px;
  font-weight: bold;
}
.hdrr {
  font-size: 20px;
  line-height: 34px;
  text-shadow: 0px 12.8392px 14.8145px rgba(0, 0, 0, 0.1);
}

.grdd {
  position: relative;
  /* margin-top: 200px; */
  margin-top: 20vh;
  /* padding-bottom: 20vh !important; */
}
.seg {
  margin-top: 100px;
  margin-bottom: 100px;
}

.seg li {
  font-size: 14px !important;
  font-weight: 500;
  line-height: 23px;
}

.sep {
  margin-bottom: 35px;
}
.sepx {
  margin-bottom: 0px !important;
}

.tyu li {
  font-size: 15px !important;
}

.sep h4 {
  font-size: 18px;
  line-height: 30px;
  font-weight: bold;
}
.sep {
  min-height: 100px;
}

.sep p {
  font-size: 14px;
  overflow: auto;
  /* line-height:23px;
  font-weight: ; */
}
.sect {
  min-height: 250px;
  padding: 25px 30px !important;
  border-radius: 10px !important;
  background: #f1fcff !important;
}

.sectx {
  min-height: 205px;
  padding: 25px 30px !important;
  border-radius: 10px !important;
  background: #f1fcff !important;
}

.sect p {
  font-size: 13px;
  line-height: 19px;
}

.footr {
  background: #64b4cd;
  width: 100%;
}

.footr p {
  font-size: 12px;
}

.links a {
  display: block;
  text-decoration: none;
  margin-bottom: 15px;
  color: #fff;
  font-size: 13px;
}

@media only screen and (max-width: 900px) {
  .moock {
    width: 100% !important;
    height: 100% !important;
    margin-top: 0 !important;
    margin-left: 0 !important;
    float: none !important;
  }

  .hdrx {
    font-size: 24px !important;
    width: 100% !important;
    line-height: inherit;
  }

  .juh {
    right: -100px !important;
    top: -100px !important;
    height: 300px !important;
  }
  .hodd {
    display: none !important;
  }

  .bgh {
    height: auto !important;
    min-height: 70px !important;
  }

  .sepx {
    margin-bottom: 25px !important;
  }
  .seg {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .sep {
    margin-left: 0 !important;
  }

  .heroimg,
  .hdrx {
    text-align: center;
    margin: auto;
  }
  /* .cont {
    padding: 30px !important;
  } */
}
</style>

