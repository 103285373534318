<template>
  <v-app class="myFont">
    <!-- <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
          transition="scale-transition"
          width="40"
        />

        <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
          width="100"
        />
      </div>

      <v-spacer></v-spacer>

      <v-btn
        href="https://github.com/vuetifyjs/vuetify/releases/latest"
        target="_blank"
        text
      >
        <span class="mr-2">Latest Release</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar> -->

    <v-app-bar
      fixed
      height="70"
      clipped-left
      class="tamb_nav"
      style="
        box-shadow: 0px 12.9792px 17.3056px rgba(0, 0, 0, 0.15);
        text-transform: capitalize !important;
      "
    >
      <div
        class="mx-auto"
        style="width: 1300px; display: flex; align-items: center"
      >
        <v-toolbar-title>
          <v-img
            src="./assets/logo.png"
            height="52"
            width="70"
            contain
            class=""
          ></v-img>
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <a href="/#inv" class="mx-5 shwonlrg"> Investing</a>
        <a href="/#edu" class="mx-5 shwonlrg"> Educate</a>
        <a href="/#por" class="mx-5 shwonlrg"> Portfolio</a>
        <a href="/#pri" class="mx-5 shwonlrg"> Pricing</a>
        <v-spacer></v-spacer>
        <v-btn
          :ripple="false"
          depressed
          dark
          rounded
          color="#64B4CD"
          class="xcaps btn shwonlrg"
          medium
          >Join Us</v-btn
        >
      </div>
      <v-app-bar-nav-icon
        class="hiddn"
        @click="drawer = true"
      ></v-app-bar-nav-icon>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" fixed right clipped app>
      <div class="mnu ml-6">
        <v-img
          src="./assets/logo.png"
          height="32"
          width="32"
          contain
          class="my-6"
        ></v-img>

        <a href="/#inv"> Investing</a>
        <a href="/#edu"> Educate</a>
        <a href="/#por"> Portfolio</a>
        <a href="/#pri"> Pricing</a>
        <a href="/privacy-policy">Privacy Policy</a>
        <a href="/terms-of-service">Terms & Conditions</a>

        <br />

        <v-btn
          :ripple="false"
          depressed
          dark
          rounded
          color="#64B4CD"
          class="xcaps btn"
          medium
          >Join Us</v-btn
        >
      </div>
    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    drawer: false,
  }),
};
</script>

<style scoped>
.myFont {
  font-family: poppins !important;
}

.tamb_nav a {
  cursor: pointer;
  text-transform: capitalize !important;
}

.mnu a {
  width: 100%;
  text-decoration: none;
  margin-bottom: 20px;
  display: block;
}

.mnu {
  padding: 20px;
}

.hiddn {
  display: none !important;
}

a {
  text-decoration: none !important;
  color: #444 !important;
}

.shwonlrg {
  display: inherit !important;
}

@media only screen and (max-width: 900px) {
  .shwonlrg {
    display: none !important;
  }
  .hiddn {
    display: block !important;
  }
}
</style>
